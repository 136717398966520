export enum EvCarProfileStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    VERIFYING = 'verifying',
    INCOMPLETE = 'incomplete',
  }
  
  export enum ChargingConnectorType {
    TYPE_1 = 'Type 1 (J1772)',
    TYPE_2 = 'Type 2 (Mennekes)',
    CHADEMO = 'CHAdeMO',
    CCS = 'CCS (Combined Charging System)',
    TESLA = 'Tesla Supercharger',
  }
  
  export interface EvCarImage {
    id: string;
    ev_car_profile_id: string;
    public_id: string;
    secure_url: string;
    type: string;
    createdAt: string;
    updatedAt: string;
  }
  
  export interface EvCarProfileFeatures {
    gps: boolean | null;
    air_conditioner: boolean | null;
    bluetooth_stereo: boolean | null;
    wifi_internet: boolean | null;
    fire_extinguisher: boolean | null;
  }

  export interface EvCarDetailsModule {
    data: EvCarProfile | null;
    updating: {
        successKeys: (keyof EvCarProfile)[];
        loadingKeys: (keyof EvCarProfile)[];
        error: string | null;
    };
  }

  export interface EvCarsState {
    evCarsDetails: Record<string, EvCarDetailsModule>;
    listModule: {
        list: EvCarProfile[];
        meta: {
            total: number;
            perPage: number;
            currentPage: number;
            lastPage: number;
        };
        isLoading: boolean;
        error: string | null;
    };
}


  export interface EvCarProfileFull extends EvCarProfile {
    // user: User
    booking_status?: string;
    // trips: TripWithRenter[]
    // airport_pickup_locations: CarPickupReturnLocation<PickupReturnAction.PICKUP>[]
    // airport_return_locations: CarPickupReturnLocation<PickupReturnAction.RETURN>[]
    // insurance_request: InsuranceRequestsReturn
  }
  
  export interface EvCarProfile {
    id: string;
    admin_id: string;
    place_id: string | null;
    address: string | null;
  
    model_make_id: string;
    model_id: number | null;
    model_name: string;
    model_year: number;
  
    battery_capacity: number;
    charging_connector_type: ChargingConnectorType;
    charging_time: number;
    range_per_charge: number;
  
    price_per_minute_ev: number;
    price_per_km_ev: number;
    price_per_minute_up_to_100_km_ev: number;
    price_per_minute_above_100_km_ev: number;
    pause_price_ev: number;
    deposit_amount_ev: number;
  
    car_type: string;
    transmission: string;
    fuel_type: string;
    no_branded_title: boolean;
    identity_country: string;
    plate_number: string;
  
    seats: number | null;
    doors: number | null;
  
    // status: EvCarProfileStatus;
    status: string;
    car_description: string | null;
  
    charging_included: boolean;
    battery_included: boolean;
    charging_policy: string | null;
  
    features: EvCarProfileFeatures | null;
    images: EvCarImage[];

    
  
    createdAt: string;
    updatedAt: string;
  }
  
  export type EvCarProfileUpdateParams = Partial<
    Pick<
      EvCarProfile,
      | 'images'
      | 'place_id'
      | 'address'
      | 'model_make_id'
      | 'model_id'
      | 'model_name'
      | 'model_year'
      | 'battery_capacity'
      | 'charging_connector_type'
      | 'charging_time'
      | 'range_per_charge'
      | 'price_per_minute_ev'
      | 'price_per_km_ev'
      | 'price_per_minute_up_to_100_km_ev'
      | 'price_per_minute_above_100_km_ev'
      | 'pause_price_ev'
      | 'deposit_amount_ev'
      | 'car_type'
      | 'transmission'
      | 'fuel_type'
      | 'identity_country'
      | 'plate_number'
      | 'seats'
      | 'doors'
      | 'car_description'
      | 'charging_included'
      | 'battery_included'
      | 'charging_policy'
      | 'no_branded_title'
    >
  > & {
    features?: EvCarProfileFeatures; 
};
  
  export type EvCarProfileUpdateableKeys = keyof EvCarProfileUpdateParams;
  export type EvCarProfileFeaturesUpdateParams = Partial<
  Pick<EvCarProfileFeatures, 'gps' | 'air_conditioner' | 'bluetooth_stereo' | 'fire_extinguisher'>
>;